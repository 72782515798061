<template>
  <div v-show="options.show" class="mb-4">
    <v-card class="pt-4">
      <v-row>
        <v-col cols="8">
          <span class="font-weight-medium text--lighten-1 text-h6 ml-4">
            <v-icon left :color="scopeConfig[rule.scope].color" class="mt-n1"
              >mdi-{{ scopeConfig[rule.scope].icon }}</v-icon
            >
            {{ rule.name }}
            <RulePopup v-if="rule.rule_id" :ruleId="rule.rule_id" />

            <v-chip
              v-if="alerts && alerts.filter((a) => !a.seen).length"
              class="ml-2"
              color="secondary"
              small
            >
              {{ alerts.filter((a) => !a.seen).length }}
            </v-chip>
          </span>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="mr-2">
            <v-btn
              v-if="options.expand"
              @click="onFiltersReset"
              class="mr-2"
              outlined
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-refresh </v-icon>
                </template>
                <span>Retirer les filtres</span>
              </v-tooltip>
            </v-btn>

            <FiltersModal
              v-if="options.expand"
              :filters="filters"
              :options="filterOptions"
              @change="onFiltersUpdate"
              @reset="onFiltersReset"
            />

            <Export
              v-show="options.expand && !loading"
              :rule_id="rule.rule_id"
              :alerts="{ [rule.rule_id]: alerts }"
              class="ml-2"
            />

            <v-btn
              v-show="!options.expand && !loading"
              @click="expand"
              class="ml-2"
            >
              Tout afficher
            </v-btn>

            <v-btn v-show="options.expand" @click="toggle" class="ml-2">
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <AlertsDataTable
        v-if="alerts && alerts.length > 0"
        :alerts="alerts"
        :headers="headers"
        :rule="rule"
        :itemsPerPage="options.itemsPerPage"
        :light="options.light"
        :loading="loading"
        :options="options"
      />
      <div v-else class="text--disabled body-1 py-5 pl-10">
        Aucune alerte pour cette règle
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatAlerts } from "@/services/alert";
import { getKeyLabel } from "@/services/rule";

export default {
  props: {
    rule: {
      type: Object,
      required: true,
    },
    viewAll: {
      type: Boolean,
    },
    dateRange: {
      type: Array,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    Export: () => import("@/components/Exports/ExportAlerts"),
    AlertsDataTable: () => import("@/components/Alerts/AlertsDataTable"),
    FiltersModal: () => import("@/components/Alerts/Filters/FiltersModal"),
    RulePopup: () => import("@/components/Rules/RulePopup"),
  },
  data: () => ({
    loading: false,
    seen: undefined,
    filters: {},
    scopeConfig: {
      "CAISSE AUTOMATIQUE": {
        color: "deep-purple",
        icon: "cash-register",
      },
      "CAISSE STANDARD": {
        color: "deep-purple",
        icon: "cash-register",
      },
      "CAISSE DRIVE": {
        color: "deep-purple",
        icon: "cash-register",
      },
      "CLIENT": {
        color: "teal",
        icon: "card-account-details-star",
      },
      "MAGASIN": {
        color: "cyan",
        icon: "tools",
      },
    },
  }),
  computed: {
    filterOptions() {
      return {
        key: {
          text: getKeyLabel("key", { rule: this.rule }) || this.$t("origin"),
          items: this.alerts.map((a) => a.key),
        },
        triggered_value: {
          text:
            getKeyLabel("triggered_value", { rule: this.rule }) ||
            this.$t("value"),
          value: [],
        },
        triggered_financial_risk: {
          text:
            getKeyLabel("triggered_financial_risk", { rule: this.rule }) ||
            this.$t("financialRisk"),
          value: [],
        },
      };
    },
    headers() {
      return [
        {
          text: this.$t("date"),
          value: "date",
          width: "100",
        },
        {
          text: getKeyLabel("key", { rule: this.rule }) || this.$t("origin"),
          value: "key",
          sortable: false,
        },
        {
          text:
            getKeyLabel("triggered_value", { rule: this.rule }) ||
            this.$t("value"),
          value: "triggered_value",
          align: "right",
          width: "25%",
        },
        {
          text:
            getKeyLabel("financial_risk", { rule: this.rule }) ||
            this.$t("financialRisk"),
          value: "triggered_financial_risk",
          align: "right",
          width: "20%",
        },
        {
          text: this.$t("duration"),
          value: "triggered_duration",
          sortable: false,
          align: "center",
          width: "15%",
        },
        {
          text: this.$t("feedback"),
          value: "feedback",
          sortable: false,
          align: "center",
          width: "80",
        },
        // {
        //   text: this.$t("comments"),
        //   value: "comment_count",
        //   sortable: false,
        //   align: "center",
        //   width: "80",
        // },
        {
          text: this.$t("analysis"),
          value: "analysis",
          sortable: "false",
          align: "center",
          width: "150",
        },
        {
          text: this.$t("detail"),
          value: "actions",
          sortable: false,
          align: "right",
          width: "20",
        },
      ];
    },
    ...mapState({
      alerts: function (state) {
        if (!state.alerts.ruleAlerts[this.rule.rule_id]) return null;

        return formatAlerts(state.alerts.ruleAlerts[this.rule.rule_id], {
          queries: state.rules.rulesNames,
        });
      },
      icons: (state) => state.alerts.alertsIcons,
    }),
  },
  methods: {
    expand: function () {
      this.$emit("onExpand", this.rule.rule_id);
    },
    toggle: function () {
      this.$emit("onToggle", this.rule.rule_id);
    },
    onFiltersUpdate: function () {
      this.loading = true;
      this.$store.dispatch("alerts/loadRuleAlerts", {
        ruleId: this.rule.rule_id,
        seen: this.seen,
        filters: this.filters,
      });
    },
    onFiltersReset: function () {
      this.filters = {};
      this.loading = true;
      this.$store.dispatch("alerts/loadRuleAlerts", {
        ruleId: this.rule.rule_id,
        seen: this.seen,
      });
    },
  },
  async created() {
    this.loading = true;
    this.$store.dispatch("alerts/loadRuleAlerts", {
      ruleId: this.rule.rule_id,
      seen: this.seen,
    });
  },
  watch: {
    alerts(val) {
      this.$emit("containerLoaded", { ruleId: this.rule.rule_id, alerts: val });
      this.loading = false;
    },
    dateRange() {
      this.loading = true;
      this.$store.dispatch("alerts/loadRuleAlerts", {
        ruleId: this.rule.rule_id,
        seen: this.seen,
      });
    },
    viewAll(val) {
      this.seen = val ? false : undefined;

      this.loading = true;
      this.$store.dispatch("alerts/loadRuleAlerts", {
        ruleId: this.rule.rule_id,
        seen: this.seen,
      });
    },
  },
};
</script>
